photo {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: var(--gallery-item-border-radius);

        opacity: 0;
    }

    img.fade-in {
        opacity: 1;
        transition: opacity 1s;
    }

    a {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

photo-caption {
    pointer-events: none;
    position: absolute;
    line-height: 1;
    text-align: end;
    font-family: sans-serif;
    font-style: normal;
    text-transform: uppercase;
}

.gallery-home-item {
    filter: brightness(100%);
    transition: filter 0.5s;

    img {
        transform: scale(1.0);
        transition: transform 0.2s;
    }
    photo-caption {
        transform: scale(1.0);
        transition: transform 0.2s;
    }
}

.gallery-home-item:hover {
    filter: brightness(120%);

    img {
        transform: scale(1.02);
        transition: transform 0.2s;
    }
    photo-caption {
        transform: scale(1.15);
    }
}
