sidebar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding-inline: calc(var(--gallery-item-border-radius)*1.5);

    whoami {
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            line-height: 0;
            color: white;
            cursor: pointer;
            text-decoration: none;
        }
    }

    links {
        flex-shrink: 0;
        line-height: 0;
        a {
            margin-inline-end: 0.5rem;
        }
    }
}

.sidebar-social-icon {
    width: 32px;
    height: 32px;
}

site-sidebar {
    padding-top: var(--home-margin-vertical);
    padding-bottom: .6rem;
    padding-inline: var(--home-margin-horizontal);

    background: linear-gradient(180deg, rgba(34,34,34,0.8) 0%, rgba(34,34,34,0) 90%);
    position:sticky;
    top:0px;
    z-index: 10;
}