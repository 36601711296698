.text-medium {
    font-weight: 500;
    letter-spacing: .4em;
    font-size: 0.8em;
}

.text-light {
    color: white;
    text-shadow:
        #00000022 0 .5em 0.2em,
        #000000cc 0 0 1em,
        #00000022 0 -.1em 0em;
}

.text-main {
    font-family: 'Marcellus SC';
    font-size: 22pt;
    color: white;
}