gallery {
    display: flex;
    flex-flow: row wrap;
    gap: calc(var(--gallery-item-border-radius) * 1.5);
    margin: calc(var(--gallery-item-border-radius) * 1.5);
    padding-inline: var(--home-margin-horizontal);
}

.gallery-album-item {
    height: var(--gallery-album-item-height);
}

.gallery-album-item:last-child {
    max-width: var(--gallery-album-item-width-last);
}

.gallery-item-std {
    flex-basis: var(--gallery-album-item-width);
}

.gallery-item-std:nth-child(3n) {
    flex-basis: var(--gallery-album-item-width-s);
    flex-shrink: 2;
    flex-grow: 2;
}

.gallery-item-std:nth-child(4n+1) {
    flex-basis: var(--gallery-album-item-width-l);
    flex-shrink: 2;
    flex-grow: 2;
}

.gallery-item-full {
    flex: 1 0 100%;
}

.gallery-home-item {
    height: var(--gallery-home-item-height);
}

.gallery-item-vertical {
    flex-basis: max-content;
}

.gallery-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: var(--gallery-item-border-radius);
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 0%, 20%);
    }
    100% {
      background-color: hsl(200, 0%, 25%);
    }
  }

  separator {
    flex: 1 0 100%;
    height: var(--col-1);
    line-height: var(--col-1);
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    vertical-align: middle;
  }

/* Full screen*/
.fullscreen-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
}

.fullscreen-image {
    max-width: var(--gallery-album-item-fullscreen-size);
    max-height: var(--gallery-album-item-fullscreen-size);
    border-radius: var(--gallery-item-border-radius);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.6);
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
